
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
  },
  setup() {
    const firstAgreement = ref(false)
    const secondAgreement = ref(false)

    return {
      firstAgreement,
      secondAgreement,
    }
  },
})
